import {
  Box,
  CssBaseline,
  BottomNavigation,
  Paper,
  Typography  
} from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";

const Footer = () => {
  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation className="ba-footer-background">
          <Typography className="ba-footer">
            <CopyrightIcon className="ba-footer-icon" />
            <img src="sama_sports_logo_full.png" height="50px" />
          </Typography>
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
export default Footer;

import { useState, useEffect } from "react";
import "../assets/css/ba-style.css";
import BookingSystem from "../config/booking-System.json";
import BookingCardsList from "../components/CzCardsView";
import { Box, LinearProgress } from "@mui/material";
import { availableGames } from "../components/CzBookingAppService";



const BookingAppCards = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [getGames, setGetGames] = useState([] as any);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const getEmailId = sessionStorage.getItem("emailId");
    const data = {
      email: getEmailId,
      hubID: 2,
    };
    let responseData = availableGames(data);

    Promise.resolve(responseData)
      .then((resp) => {
       
        if (resp.status === 1) {
          setGetGames(resp.data);
        } else if (resp.status === 0) {
          console.log("avilableGameRespError");
        }
      })
      .catch((error) => {
        console.log("avilableGameRespError", error);
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <Box sx={{ width: "100%" }} className="ba-booking-progressbar">
          <LinearProgress />
        </Box>
      ) : (
        <BookingCardsList cardDetails={BookingSystem.bookingCardDetails} availableGames={getGames}/>
      )}
    </div>
  );
};

export default BookingAppCards;

import axios from "axios";
import { BOOKING_APP_URL } from "../config/BookingAppConstants";

const accessToken = sessionStorage.getItem("access_token");
const headerObj = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

const headerObjVal = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${accessToken}`,
  },
};

//Register user.
export const userRegistration = (data: any) => {
  const userRegResponse = axios
    .post(BOOKING_APP_URL + "user/register-user", data, headerObj)
    .then((resp) => {
      let response = resp.data;
      return response;
    })
    .catch((error) => {
      return error;
    });

  return userRegResponse;
};


//user login.
export const userLogin = (data: any) => {

  const userLoginResp = axios
    .post(BOOKING_APP_URL + "user/authenticate-user", data, headerObj)
    .then((resp) => {
      let response = resp.data;
      return response;
    })
    .catch((error) => {
      return error;
    });

  return userLoginResp;
};


//get available time slot.
export const availableTimeSlot = (data: any) => { 

  const timeSlotResp = axios
    .post(BOOKING_APP_URL + "booking/get-available-timeslots", data, headerObjVal)
    .then((resp) => {
      let response = resp.data;
      return response;
    })
    .catch((error) => {
      return error;
    });

  return timeSlotResp;

};


//get user information.
export const getUserInfo =(data:any)=>{

  const getUserInfoResp = axios.post(BOOKING_APP_URL + "user/get-user-info", data, headerObjVal).then((resp)=>{
    let response = resp.data;
    return response
  }).catch((error)=>{
    return error
  })

  return getUserInfoResp;
}

//get user information for admin 
export const getAdminUserInfo =(data:any)=>{

  const getAdminUserInfoResp = axios.post(BOOKING_APP_URL + "user/get-admin-user-info", data, headerObjVal).then((resp)=>{
    let response = resp.data;
    return response
  }).catch((error)=>{
    return error
  })

  return getAdminUserInfoResp;
}

//create booking.
export const createBooking = (data:any)=>{

  const createBookingResponse = axios.post(BOOKING_APP_URL + "booking/create-booking", data,headerObjVal).then((resp)=>{
    let response = resp.data;
    return response;
  }).catch((error)=>{
    return error;
  })
  return createBookingResponse;
}

//available games
export const availableGames = (data:any)=>{
  const response = axios.post( BOOKING_APP_URL+ "booking/get-available-games", data,headerObjVal).then((resp)=>{
    let responseData = resp.data;
    return responseData;

  }).catch((error)=>{
    return error;
  })

  return response;
}

//get available price
export const getAvailablePrice = (data:any)=>{
  const response = axios.post( BOOKING_APP_URL+ 'booking/get-price-details').then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error;
  })
  return response;
}

//create full day booking 

export const getAllhubSlotDetails = (data:any)=>{
  const response = axios.post( BOOKING_APP_URL+ 'booking/get-all-hubslot-details', data, headerObjVal).then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error;
  })
  return response;
}


//get games - details
export const getBookingDetails =(data:any)=>{
  const response = axios.post(BOOKING_APP_URL + 'booking/booking-hub-details',data,headerObjVal).then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error
  })
  return response;
}

export const getUserDetails =()=>{
  const response = axios.post(BOOKING_APP_URL + 'user/get-users-list').then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error
  })
  return response;
}

export const updateUserType =(data:any)=>{
  const response = axios.post(BOOKING_APP_URL + 'user/update-user-type',data).then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error
  })
  return response;
}

export const getBookingRecordDetails =(data:any)=>{
  const response = axios.post(BOOKING_APP_URL + 'booking/booking-details',data,headerObjVal).then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error
  })
  return response;
}

//get available hub slots
export const getAvailableHubSlots = (data:any)=>{
  const response = axios.post(BOOKING_APP_URL  + 'booking/get-available-hubslots',data,headerObjVal).then((resp)=>{
    let responseData = resp.data;
    return responseData;
  }).catch((error)=>{
    return error;
  })

  return response;
}

export const updateBooking = (data:any)=>{

  const createBookingResponse = axios.post(BOOKING_APP_URL + "booking/update-booking", data,headerObjVal).then((resp)=>{
    let response = resp.data;
    return response;
  }).catch((error)=>{
    return error;
  })
  return createBookingResponse;
}

export const updatePayment = (data:any)=>{

  const createBookingResponse = axios.post(BOOKING_APP_URL + "booking/update-payment", data,headerObjVal).then((resp)=>{
    let response = resp.data;
    return response;
  }).catch((error)=>{
    return error;
  })
  return createBookingResponse;
}

export const cancelBooking = (data:any)=>{

  const cancelBookingResponse = axios.post(BOOKING_APP_URL + "booking/cancel-booking", data,headerObjVal).then((resp)=>{
    let response = resp.data;
    return response;
  }).catch((error)=>{
    return error;
  })
  return cancelBookingResponse;
}
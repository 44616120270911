import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import { updatePayment } from "./CzBookingAppService";
export interface dataGridVal {
  id: number;
  startTime: string;
  endTime: string;
  name: string;
  phoneNumber: number;
  type: string;
  numberofMembers: number;
  hubalot: string;
  field: string;
  headerName: string;
  width: number;
  editable: boolean[];
}

interface propsData {
  rows: any;
  columns: GridColDef[];
  bookingDetails: any[];
}

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  row: any;
}

interface BookingNotePopupProps {
  noteOpen: boolean;
  onClose: () => void;
  row: any;
}

const DataGridComponent: React.FC<propsData> = (props) => {
  const columnObj = props.columns.map((obj: any) => obj);
  const rowObj = props.bookingDetails.map((obj: any) => obj); //props.rows.map((obj: any) => obj);
  const userRole = sessionStorage.getItem("userRole");

  const [filterValue, setFilterValue] = useState("");
  const [enableEdit, setEnableEdit] = useState(true);
  const [reload, setReload] = useState(false);
  const [transactionReferenceError, setTransactionReferenceError] =
    useState("");

  const [editRow, setEditRow] = useState({});
  const [open, setOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);

  useEffect(() => {
    if (userRole === "admin") setEnableEdit(false);
    else setEnableEdit(true);
  }, []);

  useEffect(() => {
    //setReload(false)
  }, [reload]);

  const Popup = ({ open, onClose, onSave, row }: PopupProps) => {
    const [TXNReference, setTXNReference] = useState(row.txn_reference);
    const navigate = useNavigate();
    const type = "admin";
    const handleTXNReferenceChange = (event: any) => {
      setTXNReference(event.target.value);
    };

    const handleSubmit = (event: any) => {
      const data = {
        bookingID: row.id,
        refNumber: TXNReference,
      };

      let responseData = updatePayment(data);

      Promise.resolve(responseData)
        .then((resp) => {
          if (resp.status === 1) {
            onSave();
            navigate("/booking-app-data-table/?type=" + type);
          } else if (resp.status === 0) {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log("error");
        });
      event.preventDefault();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Update Payment Status</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              variant="filled"
              size="small"
              value={TXNReference}
              onChange={handleTXNReferenceChange}
              helperText="Transaction Reference Number"
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const BookingNotePopup = ({
    noteOpen,
    onClose,
    row,
  }: BookingNotePopupProps) => {
    return (
      <Dialog
        open={noteOpen}
        onClose={onClose}
        fullWidth
        className="ba-dialog-content"
      >
        <DialogTitle>Booking Note</DialogTitle>

        <DialogContent>{row.booking_note}</DialogContent>

        <DialogActions>
          <Button onClick={handleBookingNoteClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const handleEdit = (row: any) => {
    setEditRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setReload(true);
    setOpen(false);
  };

  const handleBookingNote = (row: any) => {
    setEditRow(row);
    setNoteOpen(true);
  };

  const handleBookingNoteClose = () => {
    setNoteOpen(false);
  };

  const CustomImageRenderer = (props: any) => {
    const imageUrl = props.value;

    return <img src={imageUrl} alt="Image" className="ba-game-icon" />;
  };

  const Columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: "txn_reference",
      headerName: "Transaction Reference Number",
      width: 50,
      hide: true,
    },

    {
      field: "game",
      headerName: "Game",
      width: 100,
      editable: false,
      renderCell: (params) => <CustomImageRenderer value={params.value} />,
    },
    {
      field: "booking_number",
      headerName: "Booking Number",
      width: 200,
      editable: false,
    },

    {
      field: "booking_date",
      headerName: "Booking Date",
      width: 100,
      editable: false,
    },
    {
      field: "customer_name",
      headerName: "Name",
      width: 200,
      editable: false,
    },
    {
      field: "customer_contact",
      headerName: "Contact Number",
      width: 180,
      editable: false,
    },
    {
      field: "time_slot_name",
      headerName: "Time",
      width: 130,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      editable: true,
    },

    {
      field: "booking_status",
      headerName: "Booking Status",
      width: 150,
      editable: false,
      type: "singleSelect",
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 150,
      editable: false,
    },

    {
      field: "payment_status_button",
      headerName: "Update Payment Status",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.payment_status == "Paid"}
          onClick={() => handleEdit(params.row)}
        >
          Update
        </Button>
      ),
      hide: enableEdit,
    },
    {
      field: "booking_status_button",
      headerName: "Update Booking Status",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.payment_status == "Cancelled"}
          onClick={() => handleEdit(params.row)}
        >
          Cancel
        </Button>
      ),
      hide: true,
    },
    {
      field: "booking_note",
      headerName: "Booking Note",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleBookingNote(params.row)}
        >
          Note
        </Button>
      ),
    },
  ];

  const filteredRows = rowObj.filter(
    (row) =>
      row.booking_date.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.customer_contact.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.customer_name.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.game.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.booking_status.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.payment_status.toLowerCase().includes(filterValue.toLowerCase())
  );

  const getRowClassName = (params: any | undefined) => {
    let rowStyle = "";
    let currentDate = new Date().toLocaleDateString("En-IN");
    const arrDateToCompareSplitData = params.row.booking_date.split("/");
    const dateToCompareYear = parseInt(arrDateToCompareSplitData[2]);
    const dateToCompareMonth = parseInt(arrDateToCompareSplitData[1]);
    const dateToCompareDay = parseInt(arrDateToCompareSplitData[0]);

    const arrCurrentDateSplitData = currentDate.split("/");
    const CurrentDateYear = parseInt(arrCurrentDateSplitData[2]);
    const CurrentDateMonth = parseInt(arrCurrentDateSplitData[1]);
    const CurrentDateDay = parseInt(arrCurrentDateSplitData[0]);

    if (dateToCompareYear === CurrentDateYear) {
      if (dateToCompareMonth === CurrentDateMonth) {
        if (dateToCompareDay === CurrentDateDay) {
          rowStyle = "ba-date-today";
        } else if (dateToCompareDay < CurrentDateDay) {
          rowStyle = "ba-date-past";
        } else if (dateToCompareDay > CurrentDateDay) {
          rowStyle = "ba-date-future";
        }
      } else if (dateToCompareMonth < CurrentDateMonth) {
        rowStyle = "ba-date-past";
      } else if (dateToCompareMonth > CurrentDateMonth) {
        rowStyle = "ba-date-future";
      }
    } else if (dateToCompareYear < CurrentDateYear) {
      rowStyle = "ba-date-past";
    } else if (dateToCompareYear > CurrentDateYear) {
      rowStyle = "ba-date-future";
    }

    console.log(rowStyle);

    return rowStyle;
  };

  return (
    <div>
      <div className="bp-bookingList-box">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="standard"
          sx={{ width: 300 }}
          value={filterValue}
          onChange={handleFilterChange}
        />

        <DataGrid
          rows={filteredRows}
          columns={Columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          getRowClassName={getRowClassName}
        />
        <Popup
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          row={editRow}
        />

        <BookingNotePopup
          noteOpen={noteOpen}
          onClose={handleBookingNoteClose}
          row={editRow}
        />
      </div>
    </div>
  );
};
export default DataGridComponent;

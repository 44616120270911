import React, { useState, useEffect } from "react";
import {
  LinearProgress,
  Grid,
  Button,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  TextField,
  Autocomplete,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BookingAppConfig from "../config/booking-System.json"; //don't delete
import useStore from "../hooks/Store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  availableTimeSlot,
  cancelBooking,
  createBooking,
  getAvailableHubSlots,
  getBookingDetails,
} from "../components/CzBookingAppService";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const date = new Date();

const UpdateBooking = () => {

  let currentDate = convert(date);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [timeSlotArray, setTimeSlotArray] = useState([] as any);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">(
    "success" as any
  );
  const [timeSlotVal, setTimeSlotVal] = React.useState<string | null>();
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerContact, setCustomerContact] = useState("");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [bookingDate, setBookingDate] = useState(currentDate);
  const [inputSlotValue, setInputSlotValue] = React.useState("");
  const [timeSlotId, setTimeSlotId] = useState();
  const [availableHubSlots, setAvailableHubSlots] = useState([] as any);
  const [hubSlotBooking, setHubSlotBooking] = useState([] as any);
  const [hubSlotBookingIndex,setHubSlotBookingIndex] = useState(1);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [rowValue, setRowValue] = useState([] as any);

  const {
    availableGameId, selectedBookingID
  } = useStore();

  

 
  let rowsObj: any = [];
  let columnObj: any = [];
  //let rowValue: any = [];
  

  //get time slot useEffect
  useEffect(() => {
    if (availableGameId) {
      const data = {
        gameID: availableGameId,
        bookingDate: bookingDate,
      };

      let responseData = availableTimeSlot(data);

      Promise.resolve(responseData)
        .then((resp) => {
          console.log("availableTimeSlotresponseData", resp);
          if (resp.status === 1) {
            console.log("TIMESLOTRESP", resp.data);
            setTimeSlotArray(resp.data);
          } else if (resp.status === 0) {
            console.log("TIMESLOTERROR");
          }
          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("TIMESLOTERROR", error);
          // setIsLoading(false);
        });
    } else {
      navigate("/booking-app-cards");
    }
  }, [availableGameId, bookingDate, navigate]);


  useEffect(() => {
    if (selectedBookingID) {
      const data = {
        bookingID: selectedBookingID
      };
      getBookingDetails(data).then(res => {
        console.log(res);
        const respData = res.data;
        setCustomerName(respData.customerName);
        setCustomerEmail(respData.customerEmail);
        setCustomerContact(respData.customerContact);
        setTransactionNumber(respData.TXNReference);
        setBookingDate(respData.bookingDate);
        setTotalAmount(respData.amount);
        setTimeSlotId(respData.timeSlotID);
        let arrRowValue: any = [];
        respData.bookingLines.forEach((obj:any, idx: number) =>{
          arrRowValue.push({
            name: obj.name,
            slNo: idx,
            id: obj.id,
            price: obj.price
          });
        });
        setRowValue(arrRowValue);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
      });
    }
  }, [selectedBookingID]);


  useEffect(() => {});
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  const today = dayjs();
  const handleCustomerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(e.target.value);
  };

  const handleCustomerEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerEmail(e.target.value);
  };

  const handleCustomerContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerContact(e.target.value);
  };

  const handleTransactionNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionNumber(e.target.value);
  };

  function convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleCustomerDate = (newValue: any | null) => {
    const newBookingDate = newValue.$d;
    const convertDate = convert(newBookingDate);
    setBookingDate(convertDate);
  };

  let bookingSlotTime: any = [];
  if (timeSlotArray.length > 0) {
    bookingSlotTime = timeSlotArray.sort().map((obj: any) => obj.name);
  }

  const handleBookingSlotTime = (event: any, newValue: string | null) => {
    console.log("timeValue", newValue);
    setTimeSlotVal(newValue);

    let data = timeSlotArray.filter((obj: any) => obj.name === newValue);
    console.log("FILTER DATA", data?.[0].id);
    setTimeSlotId(data?.[0].id);
  };

  const handleConfirm = () => {
    if (timeSlotId) {
      const data = {
        timeSlotID: timeSlotId,
        bookingDate: bookingDate,
        gameID: availableGameId,
      };

      let response = getAvailableHubSlots(data);

      Promise.resolve(response)
        .then((resp) => {
          console.log("getAvailableHubSlotsResp", resp);
          setAvailableHubSlots(resp.data);
          setDefaultBookingSlot(resp.data);
        })
        .catch((error) => {
          console.log("getAvailableHubSlotsError", error);
        });
    } else {
      setMessage("Time Slot is mandatory.Please select the time slot");
      setSeverity("error");
      setOpen(true);
    }
  };


  const handleAdditionalBooking = () => {
    let updatedHubSlotIndex: number = hubSlotBookingIndex + 1;
    console.log("availableHubSlots", availableHubSlots);

    if (
      availableHubSlots.length > 1 &&
      hubSlotBookingIndex < availableHubSlots.length
    ) {
      rowsObj = availableHubSlots.map((obj: any) => obj);
      hubSlotBooking[hubSlotBookingIndex] = {
        name: rowsObj?.[hubSlotBookingIndex].name,
        slNo: hubSlotBookingIndex + 1,
        id: rowsObj?.[hubSlotBookingIndex].id,
        price: rowsObj?.[hubSlotBookingIndex].price
      };
      setHubSlotBooking(hubSlotBooking);
      setHubSlotBookingIndex(updatedHubSlotIndex);
      const updatedGrandTotal = totalAmount+ rowsObj?.[hubSlotBookingIndex].price;
      setTotalAmount(updatedGrandTotal);
      setRowValue(hubSlotBooking.map((obj: any) => obj));
    }
  };

  const handleResetBooking = () => {
    let resetHubSlotbooking: any = [];
    setHubSlotBookingIndex(1);
    rowsObj = availableHubSlots.map((obj: any) => obj);
    resetHubSlotbooking[0] = {
      name: rowsObj?.[0].name,
      slNo: 1,
      id: rowsObj?.[0].id,
      price: rowsObj?.[0].price
    };
    setHubSlotBooking(resetHubSlotbooking);
    setRowValue(hubSlotBooking.map((obj: any) => obj));
    console.log("hubslotbooking", hubSlotBooking);
    const updatedGrandTotal = rowsObj?.[0].price;
    setTotalAmount(updatedGrandTotal);
  };

 
  if (BookingAppConfig.gridBookingTimeSlotTable.length > 0) {
    columnObj = BookingAppConfig.gridBookingTimeSlotTable.map(
      (obj: any) => obj
    );
  }

  const setDefaultBookingSlot = (hubSlotData: any) => {
     //automatic row data => dataGrid.
  console.log("availableHubSlots");
  console.log(hubSlotData);
  if (hubSlotData.length > 0) {
    rowsObj = hubSlotData.map((obj: any) => obj);
    console.log("rowsObj", rowsObj);
    hubSlotBooking[0] = {
      name: rowsObj?.[0].name,
      slNo: 1,
      id: rowsObj?.[0].id,
      price: rowsObj?.[0].price
    }; // full data

    setTotalAmount(rowsObj?.[0].price);
    setHubSlotBooking(hubSlotBooking);

    const defaultRowValue = [hubSlotBooking[0]];
    console.log("defaultRowValue", defaultRowValue);
    setRowValue(defaultRowValue);
  }
};

  const handleCancel = () => {
    const data = {
      bookingID: selectedBookingID
    };
    cancelBooking(data).then(res => {
      navigate("/booking-app-data-table");
    }).catch(err => {

    });
  };


  const handleSubmit = () => {
    console.log("timeSlotData", timeSlotVal);
    console.log("bookingCustomerName", customerName);
    console.log("customerBookingDate", bookingDate);

    // createBooking
    const userId: number = parseInt(
      sessionStorage.getItem("userId") || "0",
      10
    );
    console.log("userId", userId);
    const data = {
      bookingID: selectedBookingID,
      amount: totalAmount,
      userID: userId,
      bookingDate: bookingDate,
      hubID: availableHubSlots?.[0].hub_id,
      TXNReference: transactionNumber,
      bookingLines: hubSlotBooking,
      timeSlotID: timeSlotId,
      customerName: customerName,
      customerEmail: customerEmail,
      customerContact: customerContact    
    };

    console.log("Booking data:");
    console.log(data);

    let responseData = createBooking(data);
    Promise.resolve(responseData)
      .then((resp) => {
        console.log("createBookingResp", resp);

        if (resp.status === 1) {
          setMessage("Booking successfully done");
          setSeverity("success");
          setOpen(true);
          navigate("/booking-app-data-table");

        } else if (resp.status === 0) {
          setMessage(resp.message);
          setSeverity("error");
          setOpen(true);
        }

        if (resp.code === "ERR_BAD_REQUEST") {
          setMessage(resp.response.data.message);
          setSeverity("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        console.log("createBookingRespError", error);
      });
  };
  return (
    <div>
      {isLoading ? (
        <Box sx={{ width: "100%" }} className="ba-booking-progressbar">
          <LinearProgress />
        </Box>
      ) : (
        <Grid>
          <Card className="ba-form-card">
            <CardActionArea>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  className="ba-booking-heading-wrapper"
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <Typography>
                      <b>
                        <h2>Booking Form </h2>
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={0.5}
                      className="ba-booking-wrapper"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={3}
                        sx={{ paddingBottom: 4 }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Name"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerName}
                          onChange={handleCustomerName}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerEmail}
                          onChange={handleCustomerEmail}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Contact Number"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerContact}
                          onChange={handleCustomerContact}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Transaction Reference Number"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={transactionNumber}
                          onChange={handleTransactionNumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          sx={{ width: 300 }}
                        >
                          <DatePicker
                            label="Booking date"
                            value={bookingDate}
                            onChange={(newValue) =>
                              handleCustomerDate(newValue)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            minDate={today}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3}>
                        <Autocomplete
                          disablePortal
                          value={timeSlotVal}
                          onChange={handleBookingSlotTime}
                          inputValue={inputSlotValue}
                          onInputChange={(event, newInputValue) => {
                            setInputSlotValue(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={bookingSlotTime}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Time slot" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3}>
                        <Button
                          variant="contained"
                          className="ba-table-btn"
                          startIcon={<ThumbUpAltIcon />}
                          onClick={handleConfirm}
                        >
                          Confirm Time Slot
                        </Button>
                      </Grid>
                      <Snackbar
                        open={open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <Alert
                          onClose={handleClose}
                          severity={severity}
                          sx={{ width: "100%" }}
                        >
                          {message}
                        </Alert>
                      </Snackbar>
                    </Grid>
                  </Box>
                </Grid>

                <Grid>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className="ba-booking-table-wrapper"
                    >
                      <Grid item xs={12} sm={12} lg={12}>
                        <Typography>
                          <h3>
                            <b>Booking details</b>
                          </h3>
                        </Typography>
                        <Box sx={{ height: 400, minWidth: "40%" }}>
                          <DataGrid
                            rows={rowValue}
                            columns={columnObj}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        </Box>
                        <Grid className="ba-booking-grid-btn">
                          <Button
                            variant="contained"
                            className="ba-table-btn"
                            onClick={handleResetBooking}
                            startIcon={<DeleteIcon />}
                          >
                            Reset Booking
                          </Button>
                        </Grid>
                        <Grid className="ba-booking-grid-btn">
                          <Button
                            variant="contained"
                            className="ba-table-btn"
                            onClick={handleAdditionalBooking}
                            startIcon={<AddIcon />}
                          >
                            Add Booking
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid>
                  <Box sx={{ minWidth: 120 }}>
                    <Grid container spacing={2} className="ba-booking-price">
                      <Grid item xs={12} sm={12} lg={12}>
                        <Typography>
                          <h3>
                            <b>Grand Total : ₹  </b>
                            {totalAmount}
                          </h3>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <FormControl
                          fullWidth
                          variant="filled"
                          className="ba-booking-price-input"
                        >
                          <InputLabel htmlFor="my-input">{}</InputLabel>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className="ba-booking-btn-warpper"
                >
                  <Button
                    variant="contained"
                    startIcon={<ThumbUpAltIcon />}
                    onClick={handleSubmit}
                  >
                    Confirm Booking
                  </Button>
                  </Grid>
                  <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className="ba-booking-btn-warpper"
                >
                  <Button
                    variant="contained"
                    startIcon={<ThumbUpAltIcon />}
                    onClick={handleCancel}
                  >
                    Cancel Booking
                  </Button>
                  </Grid>
                  <Grid>
                    <Snackbar
                      open={open}
                      autoHideDuration={5000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                      >
                        {message}
                      </Alert>
                    </Snackbar>
                  </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default UpdateBooking;

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { userType } from "../config/BookingAppConstants";
import { TextField, Button } from "@mui/material";

import { updateUserType } from "./CzBookingAppService";

interface propsData {
  userDetails: any[];
}

const DataGridComponent: React.FC<propsData> = (props) => {
  const rowObj = props.userDetails.map((obj: any) => obj); //props.rows.map((obj: any) => obj);
  const [filterValue, setFilterValue] = useState("");
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const handleEdit = (row: any) => {
    const data = {
      userID: row.id,
    };


    let response = updateUserType(data);

    Promise.resolve(response)
      .then((resp) => {
        if (resp.status === 1) {
          navigate("/users");
        }
      })
      .catch((error) => {});
  };

  const Columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 250,
      editable: false,
    },

    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 200,
      editable: false,
    },

    {
      field: "name",
      headerName: "User Type",
      width: 100,
      editable: false,
    },

    {
      field: "update_user_type",
      headerName: "Update User Type",
      width: 250,
      headerAlign: "center",
      hide: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.key_identifier === userType}
          onClick={() => handleEdit(params.row)}
        >
          Make Admin
        </Button>
      ),
    },
  ];

  

  const filteredRows = rowObj.filter(
    (row) =>
      row.full_name.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.email.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.contact_number.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.name.toLowerCase().includes(filterValue.toLowerCase())
  );


  return (
    <div>
      <div className="bp-bookingList-box">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="standard"
          sx={{ width: 300 }}
          value={filterValue}
          onChange={handleFilterChange}
        />

        <DataGrid
          rows={filteredRows}
          columns={Columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
  );
};
export default DataGridComponent;

import React, { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  LinearProgress,
  Grid,
  Button,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  TextField,
  Autocomplete,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BookingAppConfig from "../config/booking-System.json"; //don't delete
import useStore from "../hooks/Store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  availableTimeSlot,
  createBooking,
  getAvailableHubSlots,
  getAdminUserInfo,
  getAllhubSlotDetails,
} from "../components/CzBookingAppService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { endTimeID, endTimeName } from "../config/BookingAppConstants";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const date = new Date();
const AddBooking = () => {
  const userRole = sessionStorage.getItem("userRole");
  const type = userRole == "general" ? "user" : "admin";
  const userName =
    userRole == "general" ? sessionStorage.getItem("userName") : "";
  const userEmail =
    userRole == "general" ? sessionStorage.getItem("emailId") : "";
  const userContactNumber =
    userRole == "general" ? sessionStorage.getItem("contactNumber") : "";
  parseInt(sessionStorage.getItem("userId") || "0", 10);
  const user_id = parseInt(sessionStorage.getItem("userId") || "0", 10);
  const created_by = parseInt(sessionStorage.getItem("userId") || "0", 10);
  const [transactionId, setTransactionId] = useState("");
  const [bookingNote, setBookingNote] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showCourts, setShowCourts] = useState(false);
  let currentDate = convert(date);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [timeSlotArray, setTimeSlotArray] = useState([] as any);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">(
    "success" as any
  );
  const [timeSlotVal, setTimeSlotVal] = React.useState("");
  const [timeSlotName, setTimeSlotName] = React.useState<string | null>();
  const [endTimeSlotVal, setEndTimeSlotVal] = React.useState<string | null>();
  const [bookingDate, setBookingDate] = useState(currentDate);
  const [timeSlotId, setTimeSlotId] = React.useState<number | null>();
  const [availableHubSlots, setAvailableHubSlots] = useState([] as any);
  const [hubSlotBooking, setHubSlotBooking] = useState([] as any);
  const [hubSlotBookingIndex, setHubSlotBookingIndex] = useState(1);
  const [totalAmount, setTotalAmount] = React.useState<any>(0);
  const [selectedValue, setSelectedValue] = useState("no");
  const [rowValue, setRowValue] = useState([] as any);
  const {
    availableGameId,
    availableGameTypeId,
    minHours,
    minHubSlots,
    fullDayDiscount,
  } = useStore();
  const [customerName, setCustomerName] = useState(userName);
  const [customerEmail, setCustomerEmail] = useState(userEmail);
  const [customerContact, setCustomerContact] = useState(userContactNumber);
  const [userId, setUserId] = useState(user_id);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  // const [hideDeleteIcon, setHideDeleteIcon] = React.useState(false);
  const [resetCourts, setResetCourts] = React.useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [disableFullDayButtons, setDisableFullDayButtons] = useState(false);
  const [showTime, setShowTime] = useState(true);
  const [showAvailableCourts, setShowAvailableCourts] = useState(false);
  const [selectedCourts, setSelectedCourts] = React.useState<string[]>([]);
  const [availableCourts, setAvailableCourts] = React.useState<string[]>([]);
  const [availableTotalHubSlots, setAvailableTotalHubSlots] = useState(
    [] as any
  );

  let rowsObj: any = [];
  let columnObj: any = [];
  let nextTimeSlot: any;

  useEffect(() => {
    if (availableGameId) {
      const data = {
        bookingDate: bookingDate,
        gameTypeID: availableGameTypeId,
        gameID: availableGameId,
      };
      let responseData = availableTimeSlot(data);
      Promise.resolve(responseData)
        .then((resp) => {
          if (resp.status === 1) {
            if (resp.data.length > 0) setTimeSlotArray(resp.data);
            else {
              setTimeSlotArray("");
              setMessage("No timeslots available");
              setSeverity("error");
              setOpen(true);
            }
          } else if (resp.status === 0) {
            console.log("TIMESLOTERROR");
          }
          setIsLoading(false);
          setReadOnly(false);
        })
        .catch((error) => {
          console.log("TIMESLOTERROR", error);
          setIsLoading(false);
        });
    } else {
      navigate("/booking-app-cards");
    }
  }, [availableGameId, bookingDate, navigate]);

  useEffect(() => {
    if (userRole === "admin") setIsButtonVisible(true);
  }, []);

  useEffect(() => {
    handleConfirm();
    if (timeSlotId) {
      setReadOnly(true);
    }
  }, [timeSlotId]);

  useEffect(() => {
    const selectedHubSlots = availableTotalHubSlots.filter((item: any) =>
      selectedCourts.includes(item.name)
    );
    let totalPrice = selectedHubSlots.reduce(
      (total: any, currentValue: any) => {
        return total + currentValue.price;
      },
      0
    );

    const discountPrice = (totalPrice * fullDayDiscount) / 100;
    totalPrice = totalPrice - discountPrice;
    setTotalAmount(totalPrice);
    setAvailableHubSlots(selectedHubSlots);
    setHubSlotBooking(selectedHubSlots);
    setRowValue(selectedHubSlots);
  }, [selectedCourts]);

  useEffect(() => {
    if (rowValue.length === 0) {
      setShowCourts(false);
      setResetCourts(true);
      setReadOnly(false);
      setTimeSlotId(null);
    }
  }, [rowValue]);

  useEffect(() => {
    if (resetCourts === true) {
      setEndTimeSlotVal("");
      setTimeSlotVal("");
      setResetCourts(false);
    }
  }, [resetCourts]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const today = new Date();
  const maxSelectableDate = new Date();
  maxSelectableDate.setMonth(maxSelectableDate.getMonth() + 1);

  const handleCustomerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(e.target.value);
  };
  const handleCustomerEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerEmail(e.target.value);
  };
  const handleCustomerContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerContact(e.target.value);
  };
  const handleSportsPerson = () => {
    if (userRole == "admin") {
      const data = {
        contactNumber: customerContact,
      };
      let response = getAdminUserInfo(data);
      Promise.resolve(response)
        .then((resp) => {
          if (resp.status === 1) {
            setCustomerEmail(resp.data[0].email);
            setCustomerName(resp.data[0].full_name);
            setUserId(resp.data[0].id);
          } else {
            setMessage(
              "User not found.Please register the user or verify the phone number"
            );
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch((error) => {
          console.log("getAdminUserInfo", error);
        });
    }
  };

  const handleCourtsChange = (event: any) => {
    setSelectedCourts(event.target.value);
  };

  const handleFullDayBooking = (event: any) => {
    setRowValue("");
    setSelectedValue(event.target.value);
    setDisableFullDayButtons(true);
    setReadOnly(true);
    setShowTime(false);
    if (minHubSlots === 2) setShowAvailableCourts(false);
    else setShowAvailableCourts(true);
    const data = {
      timeSlotID: timeSlotId,
      bookingDate: bookingDate,
      gameTypeID: availableGameTypeId,
      gameID: availableGameId,
    };
    let response = getAllhubSlotDetails(data);
    Promise.resolve(response)
      .then((resp) => {
        if (resp.status === 1) {
          if (minHubSlots === 2) {
            let totalPrice = resp.data.reduce(
              (total: any, currentValue: any) => {
                return total + currentValue.price;
              },
              0
            );

            const discountPrice = (totalPrice * fullDayDiscount) / 100;
            totalPrice = totalPrice - discountPrice;
            setTotalAmount(totalPrice);
            setAvailableHubSlots(resp.data);
            setHubSlotBooking(resp.data);
            setRowValue(resp.data);
          } else {
            setAvailableTotalHubSlots(resp.data);
            if (resp.data.length > 0) {
              const uniqueCourts = resp.data
                .map((item: any) => item.name)
                .filter(
                  (value: any, index: any, self: any) =>
                    self.indexOf(value) === index
                );
              uniqueCourts.sort();
              setAvailableCourts(uniqueCourts);
            } else {
              setShowAvailableCourts(false);
              setMessage("Selected date is not available");
              setSeverity("error");
              setOpen(true);
            }
          }
        } else {
          setTimeSlotArray("");
          setMessage(resp.message);
          setSeverity("error");
          setOpen(true);
          handleResetBooking();
        }
      })
      .catch((error) => {
        console.log("getAvailableHubSlotsError", error);
      });
  };

  const handleSingleDayBooking = (event: any) => {
    setSelectedValue(event.target.value);
    setShowTime(true);
    setShowAvailableCourts(false);
    setDisableFullDayButtons(true);
    setReadOnly(false);
  };

  const handleTransactionId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionId(e.target.value);
    const isValidId = isValidTransactionId(transactionId);
    setIsValid(isValidId);
  };

  const handleBookingNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookingNote(e.target.value);
  };

  function convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleCustomerDate = (newValue: any | null) => {
    const newBookingDate = newValue.$d;
    const convertDate = convert(newBookingDate);
    setBookingDate(convertDate);
  };

  let bookingSlotTime: any = [];
  if (timeSlotArray.length > 0) {
    if (minHours === 2) {
      const filteredItems = timeSlotArray.filter(
        (obj: any) => obj.name !== endTimeName
      );
      bookingSlotTime = filteredItems.sort().map((obj: any) => obj.name);
    } else {
      bookingSlotTime = timeSlotArray.sort().map((obj: any) => obj.name);
    }
  }

  const handleBookingSlotTime = (event: any, newValue: any) => {
    setTimeSlotVal(newValue);
    let endTimeString: string;
    let timeString: string = newValue;
    let timeArray: string[] = timeString.split(" ");
    let hour: number = parseInt(timeArray[0]);
    let newHour: number = hour + minHours;
    let ampm: string = timeArray[1];
    if (hour === 12) {
      ampm = "AM";
    }
    if (newHour >= 12) {
      ampm = ampm === "AM" ? "PM" : "AM";
    }
    if (newHour === 12) {
      endTimeString = `${newHour} ${ampm}`;
    } else endTimeString = `${newHour % 12} ${ampm}`;
    setEndTimeSlotVal(endTimeString);
    let timeSlots = [...timeSlotArray];
    let data = timeSlots.filter((obj: any) => obj.name === newValue);
    setTimeSlotId(data?.[0].id);
    setTimeSlotName(data?.[0].play_time);
  };

  const isValidTransactionId = (id: string) => {
    const regex = /^[A-Za-z0-9]+$/;
    return regex.test(id);
  };

  const handleConfirm = () => {
    if (timeSlotId) {
      const data = {
        timeSlotID: timeSlotId,
        bookingDate: bookingDate,
        gameTypeID: availableGameTypeId,
        gameID: availableGameId,
      };
      let response = getAvailableHubSlots(data);
      Promise.resolve(response)
        .then((resp) => {
          setAvailableHubSlots(resp.data);
          setDefaultBookingSlot(resp.data);
        })
        .catch((error) => {
          console.log("getAvailableHubSlotsError", error);
        });
    }
  };

  const handleAdditionalBooking = () => {
    let updatedHubSlotIndex: number = hubSlotBookingIndex + 1;
    let additonalHubSlotBooking = [...rowValue];
    let playTime = timeSlotName;
    if (
      availableHubSlots.length > 1 &&
      hubSlotBookingIndex < availableHubSlots.length
    ) {
      rowsObj = availableHubSlots.map((obj: any) => obj);
      additonalHubSlotBooking.push({
        name: rowsObj?.[hubSlotBookingIndex].name,
        slNo: updatedHubSlotIndex,
        hubSlotID: rowsObj?.[hubSlotBookingIndex].hub_slot_id,
        price: rowsObj?.[hubSlotBookingIndex].price,
        timeSlotID: timeSlotId,
        play_time: playTime,
      });
      setHubSlotBooking(additonalHubSlotBooking);
      let lastHubSlotBookingIndex = additonalHubSlotBooking.slice(-1)[0].slNo;
      setHubSlotBookingIndex(lastHubSlotBookingIndex);
      const updatedGrandTotal =
        totalAmount + rowsObj?.[hubSlotBookingIndex].price;
      setTotalAmount(updatedGrandTotal);
      const updatedRows = additonalHubSlotBooking.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      setRowValue(updatedRows);
    }
  };

  const handleResetBooking = () => {
    setBookingDate(currentDate);
    setHubSlotBookingIndex(0);
    let resetHubSlotbooking: any = [];
    setRowValue(resetHubSlotbooking);
    setHubSlotBooking(resetHubSlotbooking);
    setTotalAmount(0);
    setShowCourts(false);
    setResetCourts(true);
    setReadOnly(false);
    setTimeSlotId(null);
    setShowAvailableCourts(false);
    setSelectedCourts(resetHubSlotbooking);
    setDisableFullDayButtons(false);
    setShowTime(true);
    setSelectedValue("no");
  };

  const handleAdditionalTime = () => {
    let valueToFind = rowValue[rowValue.length - 1].timeSlotID;

    const index = timeSlotArray.findIndex(
      (obj: { id: number | null | undefined }) => obj.id === valueToFind
    );

    if (index !== -1 && index + 1 < timeSlotArray.length) {
      nextTimeSlot = timeSlotArray[index + 1].id;
    }
    if (nextTimeSlot) {
      let timeSlots = [...timeSlotArray];
      let data = timeSlots.filter((obj: any) => obj.id === nextTimeSlot);
      if (minHours === 2) {
        if (nextTimeSlot !== endTimeID) {
          setTimeSlotName(data?.[0].play_time);
          setTimeSlotId(nextTimeSlot);
        }
      } else {
        setTimeSlotName(data?.[0].play_time);
        setTimeSlotId(nextTimeSlot);
      }
    }
  };

  if (BookingAppConfig.gridBookingTimeSlotTable.length > 0) {
    columnObj = BookingAppConfig.gridBookingTimeSlotTable.map(
      (obj: any) => obj
    );
  }
  const Icons = () => (
    <div>
      <AddIcon /> <SportsSoccerIcon /> <SportsCricketIcon />{" "}
      <SportsTennisIcon />
    </div>
  );
  const updatedColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: "play_time",
      headerName: "Play Time",
      width: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Hub Slot",
      width: 200,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Delete Row",
      width: 100,
      editable: false,
      hide: true,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleDeleteRow(params.row.id)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  const handleDeleteRow = (id: any) => {
    const rowData = [...rowValue];
    const index = rowData.findIndex((row) => row.id === id);
    if (index !== -1) {
      const updatedTableData = [...rowData];
      updatedTableData.splice(index, 1);
      setRowValue(updatedTableData);
      setHubSlotBooking(updatedTableData);
    }
  };
  const setDefaultBookingSlot = (hubSlotData: any) => {
    let hubSlotPrice: any = totalAmount;
    let hubSlotSlNo: number = 0;
    let hubSlotBookingMinHubSlots = [];
    let timeSlotValue = timeSlotId;
    let playTime = timeSlotName;
    if (hubSlotData.length > 0) {
      for (let i = 0; i < minHours; i++) {
        for (let j = 0; j < minHubSlots; j++) {
          rowsObj = hubSlotData.map((obj: any) => obj);
          hubSlotSlNo = hubSlotSlNo + 1;
          hubSlotBookingMinHubSlots.push({
            name: rowsObj?.[j].name,
            slNo: hubSlotSlNo,
            hubSlotID: rowsObj?.[j].hub_slot_id,
            price: rowsObj?.[j].price,
            play_time: playTime,
            timeSlotID: timeSlotValue,
          });
          hubSlotPrice = hubSlotPrice + hubSlotBookingMinHubSlots[j].price;
        }
        const valueToFind = timeSlotId;
        const index = timeSlotArray.findIndex(
          (obj: { id: number | null | undefined }) => obj.id === valueToFind
        );
        if (index !== -1 && index + 1 < timeSlotArray.length) {
          timeSlotValue = timeSlotArray[index + 1].id;
          playTime = timeSlotArray[index + 1].play_time;
        }
      }

      let updatedRowData = [...rowValue, ...hubSlotBookingMinHubSlots];
      const updatedRows = updatedRowData.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      setHubSlotBooking(updatedRowData);
      setRowValue(updatedRows);
      let lastHubSlotBookingIndex = hubSlotBookingMinHubSlots.slice(-1)[0].slNo;
      setHubSlotBookingIndex(lastHubSlotBookingIndex);
      setTotalAmount(hubSlotPrice);
      setShowCourts(true);
    }
  };

  const handleSubmit = () => {
    if (hubSlotBooking.length > 0) {
      if (isValid) {
        const data = {
          amount: totalAmount,
          userID: userId,
          bookingDate: bookingDate,
          hubID: availableHubSlots?.[0].hub_id,
          TXNReference: transactionId,
          bookingLines: hubSlotBooking,
          customerName: customerName,
          customerEmail: customerEmail,
          customerContact: customerContact,
          createdBy: created_by,
          updatedBy: created_by,
          gameTypeID: availableGameTypeId,
          bookingNote: bookingNote,
        };

        let responseData = createBooking(data);
        Promise.resolve(responseData)
          .then((resp) => {
            if (resp.status === 1) {
              setMessage("Booking successflly added!");
              setSeverity("success");
              setOpen(true);
              navigate("/booking-app-data-table/?type=" + type);
            } else if (resp.status === 0) {
              setMessage(resp.message);
              setSeverity("error");
              setOpen(true);
            }
            if (resp.code === "ERR_BAD_REQUEST") {
              setMessage(resp.response.data.message);
              setSeverity("error");
              setOpen(true);
            }
          })
          .catch((error) => {
            console.log("createBookingRespError", error);
          });
      } else {
        setMessage("Invalid Transaction Reference Number");
        setSeverity("error");
        setOpen(true);
      }
    } else {
      setMessage("Courts are mandatory.Please select one");
      setSeverity("error");
      setOpen(true);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Box sx={{ width: "100%" }} className="ba-booking-progressbar">
          <LinearProgress />
        </Box>
      ) : (
        <Grid>
          <Card className="ba-form-card">
            <CardActionArea>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  className="ba-booking-heading-wrapper"
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <Typography>
                      <b>
                        <h2>Book Your Game </h2>
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={0.5}
                      className="ba-booking-wrapper"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={3}
                        sx={{ paddingBottom: 4 }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Contact Number"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerContact}
                          onChange={handleCustomerContact}
                        />

                        {isButtonVisible && (
                          <Button
                            variant="contained"
                            className="ba-table-btn"
                            onClick={handleSportsPerson}
                            size="small"
                          >
                            Get Sportsperson
                          </Button>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={3}
                        sx={{ paddingBottom: 4 }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerEmail}
                          onChange={handleCustomerEmail}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={3}
                        sx={{ paddingBottom: 4 }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Name"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={customerName}
                          onChange={handleCustomerName}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={3}
                        sx={{ paddingBottom: 4 }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Payment Transaction Number"
                          variant="standard"
                          sx={{ width: 300 }}
                          value={transactionId}
                          onChange={handleTransactionId}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          sx={{ width: 300 }}
                        >
                          <DatePicker
                            label="Playing Date"
                            disabled={readOnly}
                            value={bookingDate}
                            onChange={(newValue) =>
                              handleCustomerDate(newValue)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            minDate={today}
                            maxDate={maxSelectableDate}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        sx={{ paddingBottom: 4 }}
                      >
                        <Typography>
                          <b>
                            <h3>Book Whole Day? </h3>
                          </b>
                        </Typography>

                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedValue}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                              onChange={handleFullDayBooking}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                              onChange={handleSingleDayBooking}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        {showTime && (
                          <Autocomplete
                            disablePortal
                            clearIcon={null}
                            value={timeSlotVal}
                            disabled={readOnly}
                            id="controllable-states-demo"
                            onChange={handleBookingSlotTime}
                            options={bookingSlotTime}
                            sx={{ width: 150 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Start Time"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3}>
                        {showTime && (
                          <TextField
                            value={endTimeSlotVal}
                            disabled
                            id="outlined-disabled"
                            variant="outlined"
                            label="End Time"
                            defaultValue="End Time"
                            sx={{ width: 150 }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12} sx={{ marginTop: 5 }}>
                        <TextField
                          minRows={3}
                          placeholder="Note"
                          sx={{ width: 300 }}
                          value={bookingNote}
                          onChange={handleBookingNote}
                          multiline
                          rows={3}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12}>
                        {showAvailableCourts && (
                          <Typography>
                            <b>
                              <h3>Select Court </h3>
                            </b>
                          </Typography>
                        )}
                      </Grid>
                      {showAvailableCourts && (
                        <FormControl sx={{ m: 1, width: 600 }}>
                          <Select
                            labelId="multiple-select-label"
                            id="multiple-select"
                            multiple
                            value={selectedCourts}
                            onChange={handleCourtsChange}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                          >
                            {availableCourts.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <Snackbar
                        open={open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <Alert
                          onClose={handleClose}
                          severity={severity}
                          sx={{ width: "100%" }}
                        >
                          {message}
                        </Alert>
                      </Snackbar>
                    </Grid>
                  </Box>
                </Grid>

                <Grid>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className="ba-booking-table-wrapper"
                    >
                      <Grid item xs={12} sm={12} lg={12}>
                        <Grid sx={{ marginTop: "15px", marginBottom: "15px" }}>
                          {showCourts && (
                            <Button
                              variant="contained"
                              onClick={handleAdditionalBooking}
                              startIcon={<AddIcon />}
                              sx={{
                                marginRight: "2%",
                                marginTop: "1%",
                                marginBottom: "1%",
                              }}
                              size="small"
                            >
                              Add More Courts ?
                            </Button>
                          )}
                          {showCourts && (
                            <Button
                              variant="contained"
                              onClick={handleAdditionalTime}
                              startIcon={<AddIcon />}
                              sx={{ marginTop: "1%", marginBottom: "1%" }}
                              size="small"
                            >
                              Play More Time ?
                            </Button>
                          )}
                        </Grid>
                        <span className="ba-txt-game-plan">Game Plan</span>

                        <Box
                          sx={{
                            height: 400,
                            minWidth: "40%",
                            marginTop: "30px",
                          }}
                        >
                          <DataGrid
                            rows={rowValue}
                            columns={updatedColumns}
                            pageSize={4}
                            rowsPerPageOptions={[4]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        </Box>

                        <Grid sx={{ marginTop: "40px" }}>
                          <span className="ba-txt-grand-total">
                            Grand Total : ₹ {totalAmount}
                          </span>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={4}
                          className="ba-booking-grid-btn"
                          sx={{ float: "left", marginTop: "1%" }}
                        >
                          <Button
                            variant="contained"
                            className="ba-table-btn"
                            onClick={handleResetBooking}
                            startIcon={<DeleteIcon />}
                            size="small"
                          >
                            Reset Courts
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={4}
                          className="ba-booking-grid-btn"
                          sx={{ float: "right", marginTop: "1%" }}
                        >
                          <Button
                            variant="contained"
                            className="ba-table-btn"
                            startIcon={<ThumbUpAltIcon />}
                            onClick={handleSubmit}
                            size="small"
                          >
                            Get, Set, Play!
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className="ba-booking-btn-warpper"
                >
                  <Grid>
                    <Snackbar
                      open={open}
                      autoHideDuration={5000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                      >
                        {message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default AddBooking;

import React from "react";
import {
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
  Button,
} from "@mui/material";
import "../assets/css/ba-style.css";
import { useNavigate } from "react-router-dom";
import useStore from "../hooks/Store";

export interface cardProps {
  id: number;
  title: string;
  image: string;
  description: string;
}

interface propsData {
  cardDetails: cardProps[];
  availableGames: any;
}

const BookingCardsList: React.FC<propsData> = (props) => {
  //Zustand
  const {
    setAvailableGameId,
    setAvailableGameObject,
    setAvailableGameTypeId,
    setMinHours,
    setMinHubSlots,
    setFullDayDiscount
  } = useStore();
  const navigate = useNavigate();

  const handleBookNow = (obj: any) => {
    setAvailableGameId(obj.id);
    setAvailableGameObject(obj);
    setAvailableGameTypeId(obj.game_type_id);
    setMinHours(obj.min_hours);
    setMinHubSlots(obj.min_hub_slots);
    setFullDayDiscount(obj.full_day_discount);

    navigate("/booking-form");
  };

  const getImageForName = (game_name: string) => {
    if (game_name.includes("Turf")) {
      return "turf03";
    } else if (game_name.includes("Cricket")) {
      return "cricket03";
    } else if (game_name.includes("Badminton")) {
      return "shuttle02";
    } else if (game_name.includes("Football")) {
      return "turf03";
    } else {
      return null;
    }
  };

  return (
    <div>
      <Container>
        <Grid container spacing={3} className="ba-gl-cardWrapper">
          {props.availableGames.map((obj: any, idx: any) => {
            return (
              <Grid item xs={12} sm={6} lg={3} className="ba-gl-card" key={idx}>
                <Card className="ba-turf-card">
                  <CardMedia
                    sx={{ height: 140 }}
                    image={`/images/cards/${getImageForName(
                      obj.game_name
                    )}.jpg`}
                    title="Booking Cards"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {obj.game_name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleBookNow(obj);
                      }}
                    >
                      Book now
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default BookingCardsList;

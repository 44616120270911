import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import useStore from "../hooks/Store";
import "../assets/css/ba-style.css";
import { userLogin } from "../components/CzBookingAppService";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { isEnumMember } from "typescript";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">(
    "success" as any
  );

  const { setAccessToken, setFullName } = useStore();

  const navigate = useNavigate();
  const handleForgot = () => {
    navigate("/forgot-password");
  };
  const handleSignUp = () => {
    navigate("/sign-up");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    emailRegex.test(userName);
    let data;
    if (emailRegex.test(userName)) {
      data = {
        czUserEmail: userName,
        czSecret: password,
      };
    } else {
      data = {
        czUserContactNumber: userName,
        czSecret: password,
      };
    }

    let responseData = userLogin(data);

    Promise.resolve(responseData)
      .then((resp) => {
       
        if (resp.status === 1) {
          setFullName(resp.data.full_name);
          const accessToken = resp.access_token;
          setAccessToken(accessToken);
          setMessage("Welcome!");
          setSeverity("success");
          setOpen(true);

          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("userName", resp.data.full_name);
          sessionStorage.setItem("emailId", resp.data.email);
          sessionStorage.setItem("userRole", resp.data.role);
          sessionStorage.setItem("userId", resp.data.id);
          sessionStorage.setItem("contactNumber", resp.data.contact_number);
          setTimeout(() => {
            navigate("/booking-app-cards");
          }, 2000);
        } else if (resp.status === 0) {
          setMessage(resp.message);
          setSeverity("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        console.log("loginError", error);
        setMessage("Something went wrong!");
        setSeverity("error");
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Card sx={{ minWidth: 275 }} className="ba-login-card-warpper">
                <CardContent className="ba-login-content d-flex flex-column align-items-center">
                  {/* <Typography className="ba-login-span">Login</Typography> */}
                  <Typography>
                    <img
                      className="ba-login-header"
                      src="/sama_sports_logo_full.png"
                    />
                    <form onSubmit={handleSubmit}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Email or Phone Number"
                          variant="standard"
                          type="text"
                          className="ba-textField"
                          value={userName}
                          onChange={(e) => {
                            setUserName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Password"
                          variant="standard"
                          type="password"
                          className="ba-textField"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </Grid>
                      <Stack
                        spacing={2}
                        direction="row"
                        className="ba-login-btn-wrapper"
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="ba-login-btn"
                        >
                          Get Set Play!
                        </Button>
                      </Stack>
                      <Grid>
                        <Snackbar
                          open={open}
                          autoHideDuration={5000}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Alert
                            onClose={handleClose}
                            severity={severity}
                            sx={{ width: "100%" }}
                          >
                            {message}
                          </Alert>
                        </Snackbar>
                      </Grid>
                    </form>
                  </Typography>
                  <Grid className="ba-login-footer-span">
                    <Typography
                      className="ba-login-signup-span"
                      onClick={handleSignUp}
                    >
                      Click here to Join Us
                    </Typography>
                    <Typography
                      className="ba-login-signup-span"
                      onClick={handleForgot}
                    >
                      I forgot my password
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Login;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import BookingAppCard from "./pages/BookingAppCards";
import DataTable from "./pages/DataTable";
import AddBooking from "./pages/AddBooking";
import UsersList from  "./pages/Users";
import Header from "./components/header/CzHeader";
import Footer from "./components/CzFooter";
import UpdateBooking from "./pages/UpdateBooking";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#505E3B",
      },
      secondary: {
        main: "#7C953A",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/booking-app-cards" element={<BookingAppCard />} />
          <Route path="/booking-app-data-table" element={<DataTable />} />
          <Route path="/booking-form" element={<AddBooking />} />
          <Route path="/booking-details" element={<UpdateBooking />} />
          <Route path="/users" element={<UsersList />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  CssBaseline,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import { menuDetails, adminMenuDetails } from "./CzMenuDetails";
import LoginIcon from "@mui/icons-material/Login";
import { getUserInfo } from "../CzBookingAppService";
type Anchor = "top" | "left" | "bottom" | "right";

const Header = () => {
  const [sideBar, setSideBar] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [userData, setUserData] = useState('');

  //useEffect for getting user info
  useEffect(() => {
    const getEmailId = sessionStorage.getItem("emailId");

    const data = {
      email: getEmailId,
    };

    let responseData = getUserInfo(data);

    Promise.resolve(responseData)
      .then((resp) => {
        console.log("promise-getUserInfo", resp);
      })
      .catch((error) => {
        console.log("promise-getUserInfoError", error);
      });
  }, []);

  const userNameVal = sessionStorage.getItem("userName");
  const userRole = sessionStorage.getItem("userRole");

 

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const menuDts = menuDetails.map((menuObj: any, idx: any) => {
    return (
      <div>
        <List key={idx}>
          <Link
            to={menuObj.path}
            color="inherit"
            className="ba-header-listLink"
          >
            <ListItem button>
              {menuObj.icon}
              <Typography color="inherit" className="ba-header-menuList">
                {menuObj.name}
              </Typography>
            </ListItem>
          </Link>
        </List>
      </div>
    );
  });

  const adminDts = adminMenuDetails.map((menuObj: any, idx: any) => {
    return (
      <List key={idx}>
        <Link to={menuObj.path} color="inherit" className="ba-header-listLink">
          <ListItem button>
            {menuObj.icon}
            <Typography color="inherit" className="ba-header-menuList">
              {menuObj.name}
            </Typography>
          </ListItem>
        </Link>
      </List>

    );
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setSideBar({ ...sideBar, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid className="ba-drawer-header"></Grid>
      {userNameVal && userRole === "general" ? (
        menuDts
        
      ) : userNameVal && userRole === "admin" ? (
        adminDts
      ) : (
        <List>
          <Link to="/" color="inherit" className="ba-header-listLink">
            <ListItem button>
              <LoginIcon />
              <Typography color="inherit" className="ba-header-menuList">
                {"Login"}
              </Typography>
            </ListItem>
          </Link>
        </List>
      )}
    </Box>
  );

  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("emailId");
    navigate("/");
    handleClose();
  };
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav" className="ba-header-background">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>

            {/* sidebar */}
            <Drawer
              anchor={"left"}
              open={sideBar["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>

            {/* sidebar */}

            <img
              src={`${process.env.PUBLIC_URL}/sama_sports_logo.png`}
              height="32"
              className="app-logo"
            />
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
                fontWeight: "bold",
              }}
            >
              Sama Sports Hub
            </Typography>

            {userNameVal ? (
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Stack direction="row" spacing={2}>
                  <Avatar sx={{ width: 27, height: 27 }} />
                </Stack>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{ color: "#FFBB17", fontWeight: "bold" }}
                >
                  {userNameVal}
                </Button>
                { <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {<MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography>Logout</Typography>
                  </MenuItem> }
                </Menu> }
              </Grid>
            ) : null}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Header;

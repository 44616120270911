import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Grid,
  Container,
  LinearProgress,
  Box,
  Button
} from "@mui/material";
import BookingList from "../components/CzBookingList";
import BookingSystem from "../config/booking-System.json";
import { getBookingDetails } from "../components/CzBookingAppService";
import { HUB_ID } from "../config/BookingAppConstants";



const DataTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState([] as any);
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const userId: number = parseInt(
      sessionStorage.getItem("userId") || "0",
      10
    );

    const data = {
      userID: userId,
      hubID: HUB_ID,
      type: type,
    };
    let responseData = Promise.resolve(getBookingDetails);
    let response = getBookingDetails(data);

    Promise.resolve(response)
      .then((resp) => {
       
        if (resp.status === 1) {
          setBookingDetails(resp.data);
        }
      })
      .catch((error) => {
        console.log("getBookingDetailsError", error);
      });
  }, [location]);
  return (
    <div>
      {isLoading ? (
        <Box sx={{ width: "100%" }} className="ba-booking-progressbar">
          <LinearProgress />
        </Box>
      ) : (
        <Container className="ba-root-container">
          <Grid container className="ba-table-title">
            <Typography>
              <h2>
                <b>Game Plans</b>
              </h2>
            </Typography>
          </Grid>
          <Grid>
            <BookingList
              bookingDetails={bookingDetails}
              rows={BookingSystem.dataGridRow}
              columns={BookingSystem.bookingDetailsColumn}
            />
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default DataTable;

import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Logout from "@mui/icons-material/Logout";

export const menuDetails = [
  {
    name: "Book Now",
    path: "/booking-app-cards",
    icon: <DashboardIcon style={{ color: "#706b6b" }} />,
  },
  {
    name: "My Bookings",
    path: "/booking-app-data-table?type=user",
    icon: <ListAltIcon style={{ color: "#706b6b" }} />,
  },  
];

export const adminMenuDetails = [
  {
    name: "Book Now",
    path: "/booking-app-cards",
    icon: <DashboardIcon style={{ color: "#706b6b" }} />,
  },
  {
    name: "Register Player",
    path: "/sign-up",
    icon: <ListAltIcon style={{ color: "#706b6b" }} />,
  },
  {
    name: "All Bookings",
    path: "/booking-app-data-table?type=admin",
    icon: <ListAltIcon style={{ color: "#706b6b" }} />,
  },
  {
    name: "My Bookings",
    path: "/booking-app-data-table?type=user",
    icon: <ListAltIcon style={{ color: "#706b6b" }} />,
  },  
  {
    name: "Players List",
    path: "/users",
    icon: <ListAltIcon style={{ color: "#706b6b" }} />,
  },  
];

import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  LinearProgress,
  Box,
} from "@mui/material";
import UsersList from "../components/CzUsersList";
import { getUserDetails } from "../components/CzBookingAppService";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState([] as any);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const userId: number = parseInt(
      sessionStorage.getItem("userId") || "0",
      10
    );

    let response = getUserDetails();

    Promise.resolve(response)
      .then((resp) => {
        if (resp.status === 1) {
          setUserDetails(resp.data);
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <div>
      {isLoading ? (
        <Box sx={{ width: "100%" }} className="ba-booking-progressbar">
          <LinearProgress />
        </Box>
      ) : (
        <Container className="ba-root-container">
          <Grid container className="ba-table-title">
            <Typography>
              <h2>
                <b>Users List</b>
              </h2>
            </Typography>
          </Grid>
          <Grid>
            <UsersList userDetails={userDetails} />
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default Users;

import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/ba-style.css";
import { userRegistration } from "../components/CzBookingAppService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

//snackBar
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignUp = () => {
  const userRole = sessionStorage.getItem("userRole");
  const [userSignup, setUserSignUp] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState("form-control");
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("Join Us");
  const [login, setLogin] = useState(true);
  const [severity, setSeverity] = useState<"success" | "error">(
    "success" as any
  );

  useEffect(() => {
    if (isCPasswordDirty) {
      if (userSignup.password === userSignup.confirmPassword) {
        setShowErrorMessage(false);
        setCPasswordClass("form-control is-valid");
      } else {
        setShowErrorMessage(true);
        setCPasswordClass("form-control is-invalid");
      }
    }
  }, [userSignup, isCPasswordDirty, cPasswordClass]);

  useEffect(() => {
    if (userRole === "admin") {
      setHeader("Register Player");
      setLogin(false);
    }
  });

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSignUp((userSignup) => ({
      ...userSignup,
      [name]: value,
    }));
  };

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSignUp((userSignup) => ({
      ...userSignup,
      [name]: value,
    }));
    setIsCPasswordDirty(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   

    const data = {
      firstName: userSignup.firstName,
      lastName: userSignup.lastName,
      email: userSignup.emailAddress,
      contactNumber: userSignup.phoneNumber,
      authSecret: userSignup.password,
    };

    if (userSignup.password === userSignup.confirmPassword) {
      const responseData = userRegistration(data);
      Promise.resolve(responseData)
        .then((resp) => {
          console.log("loginPromiseResp", resp);
          if (resp.status === 1) {
            setMessage("Account successfully created!");
            setSeverity("success");
            setOpen(true);
            handleResetCard();
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else if (resp.status === 0) {
            setMessage(resp.message);
            setSeverity("error");
            setOpen(true);
          }

          if (resp.code === "ERR_BAD_REQUEST") {
            console.log("response Code....");
            setMessage(resp.response.data.message);
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch((error) => {
          console.log("signup ERROR", error);
        });
    }
  };

  const handleResetCard = () => {
    setUserSignUp((userSignup) => ({
      ...userSignup,
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    }));
  };

  //snackBar handleClose function
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Card sx={{ minWidth: 275 }} className="ba-signup-card-warpper">
                <CardContent className="ba-login-content d-flex flex-column align-items-center">
                  <Typography className="ba-login-span">{header}</Typography>
                  <Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="First name"
                          variant="standard"
                          name="firstName"
                          type="text"
                          className="ba-textField"
                          value={userSignup.firstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Last name"
                          name="lastName"
                          variant="standard"
                          type="text"
                          className="ba-textField"
                          value={userSignup.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Email"
                          variant="standard"
                          name="emailAddress"
                          type="email"
                          className="ba-textField"
                          value={userSignup.emailAddress}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Phone number"
                          variant="standard"
                          name="phoneNumber"
                          type="tel"
                          inputProps={{ maxLength: 10 }}
                          className="ba-textField"
                          value={userSignup.phoneNumber}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Password"
                          variant="standard"
                          name="password"
                          type="password"
                          className="ba-textField"
                          value={userSignup.password}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Confirm password"
                          name="confirmPassword"
                          variant="standard"
                          type="password"
                          className="ba-textField"
                          value={userSignup.confirmPassword}
                          onChange={handleConfirmPassword}
                        />

                        {showErrorMessage && isCPasswordDirty ? (
                          <div className="ba-confirmPassword">
                            <Typography>Passwords did not match</Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Stack
                        spacing={2}
                        direction="row"
                        className="ba-login-btn-wrapper"
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="ba-login-btn"
                        >
                          {header}
                        </Button>
                      </Stack>
                      <Grid>
                        <Snackbar
                          open={open}
                          autoHideDuration={5000}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Alert
                            onClose={handleClose}
                            severity={severity}
                            sx={{ width: "100%" }}
                          >
                            {message}
                          </Alert>
                        </Snackbar>
                      </Grid>
                    </form>
                  </Typography>
                  {login && (
                    <Grid className="ba-login-footer-span">
                      <Typography
                        className="ba-login-signup-span"
                        onClick={handleLogin}
                      >
                        Click here to login
                      </Typography>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default SignUp;

import create from "zustand";

interface LoginState {
  accessToken: string;
  setAccessToken: (accessToken: string) => void;

  fullName: string;
  setFullName: (fullName: string) => void;

  availableGameId: number;
  setAvailableGameId: (availableGameId: number) => void;

  availableGameTypeId: number;
  setAvailableGameTypeId: (availableGameId: number) => void;

  minHours: number;
  setMinHours: (minHours: number) => void;

  fullDayDiscount: number;
  setFullDayDiscount: (fullDayDiscount: number) => void;

  minHubSlots: number;
  setMinHubSlots: (minHubSlots: number) => void;

  userId: number;
  setUserId: (userId: number) => void;

  availableGameObject: any;
  setAvailableGameObject: (availableGameObject: any) => void;

  selectedBookingID: any;
  setSelectedBookingID: (selectedBookingID: any) => void;


}

const useStore = create<LoginState>((set) => ({
  //select time slot
  selectTimeSlot: [],
  setSelectTimeSlot: (selectTimeSlot: any) => {
    set((state) => ({
      ...state,
      selectTimeSlot,
    }));
  },

  //select time slot index
  timeSlotIndex: 0,
  setTimeSlotIndex: (timeSlotIndex: number) => {
    set((state) => ({
      ...state,
      timeSlotIndex,
    }));
  },

  accessToken: "",
  setAccessToken: (accessToken) => {
    set((state) => ({
      ...state,
      accessToken,
    }));
  },

  fullName: "",
  setFullName: (fullName) => {
    set((state) => ({
      ...state,
      fullName,
    }));
  },

  availableGameId: 0,
  setAvailableGameId: (availableGameId) => {
    set((state) => ({
      ...state,
      availableGameId,
    }));
  },
  availableGameTypeId: 0,
  setAvailableGameTypeId: (availableGameTypeId) => {
    set((state) => ({
      ...state,
      availableGameTypeId,
    }));
  },
  minHours: 0,
  setMinHours: (minHours) => {
    set((state) => ({
      ...state,
      minHours,
    }));
  },
  fullDayDiscount: 0,
  setFullDayDiscount: (fullDayDiscount) => {
    set((state) => ({
      ...state,
      fullDayDiscount,
    }));
  },
  minHubSlots: 0,
  setMinHubSlots: (minHubSlots) => {
    set((state) => ({
      ...state,
      minHubSlots,
    }));
  },
  userId: 0,
  setUserId: (userId) => {
    set((state) => ({
      ...state,
      userId,
    }));
  },

  availableGameObject: [],
  setAvailableGameObject: (availableGameObject) => {
    set((state) => ({
      ...state,
      availableGameObject,
    }));
  },

  selectedBookingID: "",
  setSelectedBookingID: (selectedBookingID) => {
    set((state) => ({
      ...state,
      selectedBookingID,
    }));
  },
}));

export default useStore;

import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import "../assets/css/ba-style.css";


const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/");
  };
  return (
    <div>
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Card sx={{ minWidth: 275 }} className="ba-login-card-warpper">
                <CardContent className="ba-login-content d-flex flex-column align-items-center">
                  <Typography className="ba-login-span">
                    Forgot your password?
                  </Typography>
                  <Typography>
                    <form>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        className="ba-forgot-textField"
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Email"
                          variant="standard"
                          type="email"
                          className="ba-forgot-textField"
                        />
                      </Grid>

                      <Stack
                        spacing={2}
                        direction="row"
                        className="ba-login-btn-wrapper"
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="ba-login-btn"
                        >
                          Reset password
                        </Button>
                      </Stack>
                    </form>
                  </Typography>
                  <Grid className="ba-login-footer-span">
                    <Typography className="ba-login-signup-span">
                      <span onClick={handleLogin}>Back to login</span>
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ForgotPassword;
